<template>
<!--      公共头部区域-->
<!--    <defaultHeader />-->
  <header_top />
  <header_nav v-if="Route.path !== '/'" />
<!--      页面内容区域-->
    <main class="main" id="main">
      <slot />
    </main>
<!--      公共底部区域-->
  <defaultFooter />
  </template>


<script setup>
// import DefaultHeader from "./components/defaultHeader.vue"
import DefaultFooter from "./components/defaultFooter.vue"
import { useRoute } from "vue-router"
import Header_top from "./components/headers/header_top.vue";
import Header_nav from "./components/headers/header_nav.vue";

const Route = useRoute()

if (process.client) {
  let el = document.getElementById('main');
  if (el){
    if (isMobile()) {
      el.style.minHeight = `auto!important`
    } else {
      el.style.minHeight = `calc(100vh - 88px - 429px - 0px)`
    }
  }

}
function isMobile() {
  let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}
</script>

<style scoped lang="scss">
</style>