<template>
  <header>
    <div class="header">
      <nuxt-link to="/" class="logo">
        <img src="~/assets/images/pages/index/logo.png" alt="logo" />
      </nuxt-link>
      <div class="searchBox">
        <div class="box">
          <input type="text" v-model="parmas.content" class="sousuo" placeholder="请输入关键字进行搜索" @keyup.enter="search">
          <div class="button" @click="search" title="去搜索.."><img src="../../../assets/images/pages/public/search.png" alt=""></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
const parmas = ref({
  content: ''
})


function search() {
  window.location.href = `/sousuo?query=${decodeURI(parmas.value.content)}`
}

</script>

<style scoped lang="scss">
header {
  box-shadow: 8px 4px 38px 1px rgba(0, 10, 39, 0.1);
  width: 100%;
  position: relative;
  .header {
    width: var(--body-min-width);
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //justify-content: center;
    > .logo {
      //width: 360px;
      width: 250px;
      height: 70px;
      cursor: pointer;
      position: absolute;
      left: 2vw;

      > img {
        width: 100%;
        height: 100%;
      }
    }
    .searchBox{
      position: absolute;
      right: 3vw;
      .box{
        display: flex;
        border: 1px solid #C4C4C4;
        border-right: none;
        //height: 44px;
        border-radius: 4px;
        line-height: 44px;
        .sousuo{
          border-radius: 4px;
          width: 360px;
          height: 44px;
          border: none;
          padding: 0 3px;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0;
          color: #333;
          outline: none;
          //overflow: hidden;
          background: #fff;
        }
        .button{
          width: 60px;
          height: 46px;
          border: none;
          cursor: pointer;
          background: var(--main-blue-color);
          margin-top: -1px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .box:hover{
        border-color: var(--main-blue-color);
      }
    }
  }
}
</style>