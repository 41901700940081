<template>
<footer>
  <div class="mainContainer links">
    <div class="self">
      <div class="selfLink">
        <a href="http://171.12.11.108:8088/Log/In?Redirect=%2f" target="_blank" rel="noreferrer">路桥集团OA系统</a>
        <a href="https://www.zzrbpt.com" target="_blank" rel="noreferrer">智慧路桥数字平台</a>
      </div>
      <img src="~/assets/images/pages/index/gongzhonghao.png" alt="gongzhonghao" class="gongzhonghao">
    </div>
    <div class="other">
      <div class="head">
        <div class="headItem" :class="{active: showLinks === 0}" @click="showLinks = 0">友情链接</div>
        <div class="headItem" :class="{active: showLinks === 1}" @click="showLinks = 1">子分公司</div>
      </div>
      <div v-if="showLinks === 0" class="friendLinks">
        <div class="linksItem">
          <a href="http://www.gov.cn/" target="_blank">中国政府网</a>
        </div>
        <div class="linksItem">
          <a href="http://cpc.people.com.cn/index.html" target="_blank">中国共产党新闻网</a>
        </div>
        <div class="linksItem">
          <a href="http://www.henan.gov.cn/" target="_blank">河南省人民政府</a>
        </div>
        <div class="linksItem" style="display: flex; justify-content: space-between; align-items: center">
          <a href="http://www.people.com.cn/" target="_blank" style="text-align: left; width: auto">人民网</a>
          <a href="http://www.xinhuanet.com/" target="_blank" style="text-align: left; width: auto">新华网</a>
        </div>
        <div class="linksItem">
          <a href="https://jtyst.henan.gov.cn/" target="_blank">河南省交通运输厅</a>
        </div>
        <div class="linksItem">
          <a href="http://www.zgjtb.com/" target="_blank">中国交通新闻网</a>
        </div>
        <div class="linksItem">
          <a href="https://zzjt.zhengzhou.gov.cn/" target="_blank">郑州市交通运输局</a>
        </div>
      </div>
      <div v-if="showLinks === 1" class="childLinks">
        <div class="linksItem">
          <a href="https://www.elephantgo.cn/" target="_blank">河南大象智行科技有限公司</a>
        </div>
        <div class="linksItem">
          <a href="http://www.zzsgl.com/index.html" target="_blank">郑州公路工程公司</a>
        </div>

      </div>
    </div>
  </div>
    <div class="copyright">
        <p>Copyright © {{ new Date().getFullYear() }} 郑州路桥建设投资集团有限责任公司 All Rights Reserved.</p>
        <p>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noreferrer">豫ICP备13022622号-1</a>
            <img src="../../assets/images/pages/public/gaba.png" />
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=41010202003329" rel="noreferrer" target="_blank">豫公网安备41010202003329</a>
        </p>
    </div>
</footer>
</template>

<script setup>
import {ref} from "vue";

let showLinks = ref(0)
</script>

<style scoped lang="scss">
$fontColor: #11336d;
  footer {
    border-top: 1px solid #d8d8d8;
    .links {
      min-height: 180px;
      padding-top: 75px;
      padding-bottom: 50px;
      display: flex;
      align-items: flex-start;
      a {
        color: $fontColor;
      }
      .self {
        width: 750px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .selfLink {
          display: flex;
          flex-direction: column;
          margin-right: 60px;
          margin-left: 30px;
          a {
            line-height: 1.7;
            font-size: 20px;
            font-weight: bold;
          }
        }
        .gongzhonghao {
          width: 150px;
          height: 150px;
        }
      }
      .other {
        flex: 1;
        .head {
          display: flex;
          border-bottom: 2px solid #143264;
          color: $fontColor;
          font-weight: bold;
          font-size: 24px;
          .headItem {
            margin-right: 60px;
            cursor: pointer;
            line-height: 2.1;
          }
          .active {
            position: relative;
            &:after {
              width: 100%;
              height: 2px;
              //background-color: #fd0101;
              background-color: #143264;
              display: inline-block;
              content: "";
              position: absolute;
              left: 0;
              bottom: -4px;
            }
          }
        }
        .friendLinks, .childLinks {
          display: flex;
          flex-wrap: wrap;
          width: 320px;
          margin-top: 20px;
         .linksItem {
           width: 140px;
           margin-right: 40px;
           line-height: 1.67;
           font-weight: bold;
           &:nth-child(2n) {
             margin-right: 0  ;
           }
           //a:nth-child(2) {
           //  margin-left: 16px;
           //}
         }
        }
        .friendLinks {
          .linksItem {
            a {
              display: inline-block;
              width: 100%;
              text-align: justify;
              &:after{
                display: inline-block;
                width: 100%;
                content: '';
              }
            }
          }
        }
        .childLinks {
          width: 440px;
          .linksItem {
            width: 200px;
          }
        }
      }
    }
    > .copyright {
      background-color: #143264;
      width: 100%;
      color: #A6D0E9;
      font-size: 16px;
      display: flex;
      align-items: center;
        flex-direction: column;
      justify-content: center;
        padding: 50px 0;
        line-height: 2;
        > p {
            display: flex;
            align-items: center;
            a {
                color: #A6D0E9;
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
            }
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
        }
    }
  }
</style>